import React from "react"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import { Icon } from "react-icons-kit"
import { rain } from "react-icons-kit/iconic/rain"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexEN = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const  fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo:"https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": `https://netbistrot.com${location.pathname}`,
        url: `https://netbistrotcom${location.pathname}`,
        name: "NetBistrot",
        inLanguage: "es-ES",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency | 404"
        description="Parece que esta página llovió para fuera de nuestra nube."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="websites, pwa, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="h-10" />
      <div className="container mx-auto">
        <div className=" mx-2 md:mx-0">
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <h1>404</h1>
              <p>Parece que esta página llovió para fuera de nuestra nube.</p>
            </div>
          </div>
          <div className="h-10" />
          <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
            <div className="col-span-2" />
            <div className="col-span-8 text-center">
              <Icon size={"340px"} icon={rain} />
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="h-10" />
      <Footer lang="en" />
    </>
  )
}

export default IndexEN
